body {
  margin: 0;
  font-family:'Open Sans', Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.tributeButton {
  border: 2px solid rgb(14, 91, 99);
  color: rgb(14, 91, 99);
  background-color: white;
}

.tributeButton:hover {
  background-color: rgb(14, 91, 99);
  border: 2px solid rgb(14, 91, 99);
  color: white;
}

.tributeButton:focus {
  background-color: rgb(14, 91, 99);
  border: 2px solid rgb(14, 91, 99);
  color: white;
}

.navbar-brand {
  cursor: pointer;
}

h1.fancy {
  font-family: 'Poiret One', 'Open Sans', Arial, Helvetica, sans-serif;
  font-weight: bolder;
  font-size: 36px;
}

fieldset {
  border: solid 2px #dddddd;
  background: #F5F9FA;
  padding: 1.25rem;
  margin: 1.125rem 0;
}

fieldset legend {
  font-weight: bolder;
  padding: 0 0.3rem;
  margin: 0;
  margin-left: -0.1875rem;
  font-size: 18px;
  width: auto;
  font-family: 'Poiret One', 'Open Sans', Arial, Helvetica, sans-serif;
}

label {
  font-size: 14px;
  font-weight: 600;
}

input {
  font-size: 14px;
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  font-size: 14px;
}

.input-group-text {
  font-size: 14px;
}

.center {
  text-align: center;
}

.noButton {
  padding: 0;
  margin: 0;
  border-width: 0;
  vertical-align: baseline !important;
}

.tab-content {
  padding: 20px;
  border-top: 0px;
  border-bottom: 1px solid rgb(222, 226, 230);
  border-left: 1px solid rgb(222, 226, 230);
  border-right: 1px solid rgb(222, 226, 230);
  border-radius: 5px;
}

.selectedImage {
  border: 2px solid rgb(14, 91, 99);
  border-radius: 5px;
  padding: 5px;
  margin-bottom: 5px;
}

.clickableImage {
  cursor: pointer;
  border-radius: 5px;
  margin: 5px;
}

.hidden {
  display: none;
}

.dropzone {
  border: 2px #eeeeee dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  padding: 20px;
  align-items: center;
  text-align: center;
  cursor: pointer;
}

.dropzone p {
  margin-block-end: 0;
}

.photoAlbumPhoto {
  max-width: 200px;
}

.enlargedImage {
  max-height: 100%;
  text-align: center;
}

.red {
  color: red;
}

.bigImage {
  max-height: 100%;
}
.template1PhotoCommentText {
  width: 100%;
}
.template1PhotoCommentByline {
  width: 100%;
  text-align: right;
  font-style: italic;
}

.leftPad20 {
  padding-left: 20px;
}

.leftMargin20 {
  margin-left: 20px;
}

.imageMax {
  max-height: 200px;
  padding: 10px;
  border: 2px solid rgba(0,0,0,0);
}

.imageMax:hover {
  border: 2px solid blue;
  cursor: pointer;
}

.imageSelected {
  border: 2px solid blue;
  cursor: pointer;
}

@media (max-width: 376px) {
  .bottomPadding {
    padding-bottom: 20px;
  }
}

.ais-SearchBox-submit {
  margin-left: 10px;
  margin-right: 10px;
}